/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius:3px;
}
::-webkit-scrollbar-thumb:hover{
  background: #555;
  width:10px;
}
